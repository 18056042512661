@font-face {
  font-family: 'BentonSansRegular';
  src: url('./fonts/BentonSans/Benton_Sans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'BentonSansMedium';
  src: url('./fonts/BentonSans/Benton_Sans-Medium.otf') format('opentype');
}
@font-face {
  font-family: 'BentonSansBold';
  src: url('./fonts/BentonSans/Benton_Sans-Bold.otf') format('opentype');
}

body,
html {
  background-color: #f4f5f7;
  margin: 0;
  font-family:
    BentonSansRegular,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100%;
}
#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
